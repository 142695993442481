import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../components/seo/SEO'
import Img from 'gatsby-image'
import {RichText} from 'prismic-reactjs';

const linkResolver = (doc) => {
  switch (doc.type) {
      case ('home'): {
        return doc.lang === "en-gb" ? '/en' : '/'
        // return '/'
      }
  }
}

export const query = graphql`
	query {
		global: prismicGlobal(lang: {eq: "fr-fr"}) {
      ..._prismicGlobal
    } 
	}
`

// const query = graphql`
//   query NotFoundPage {
//     global: prismicGlobal(lang: {eq: "fr-fr"}) {
//       ..._prismicGlobal
//     }  
//   }
// `

const Page404 = () => {
  const { 
      global
    } = useStaticQuery(query)
// console.log(options)
  const {
    title,
    description,
    poster,
    image_404,
    message_404
  } = global.data
// console.log(global)
  return (
    <div>
      <SEO
        pageTitle={title.text}
        pageDescription={description.text}
        pageBanner={poster.url}
        template="template-404"
        // pathname={path}
      />

      <div className="row">
        <div className="card card-home col-md-6 col-xs-12">
          <div className="outter" style={{
              backgroundColor: '#EDEDED'
            }}>
            <div className="inner">
              <div className="card-image">
                <Img {...image_404} />
              </div>
              <div className="card-body h100">
                <div className="top">
                  <div className="texte fL strong">
                    <RichText
                      render={message_404.raw}
                      linkResolver={linkResolver}
                    />
                    {/* {RichText.render(message_404.raw)} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page404

